// custom theme palettes are passed into a theme function
$matx-theme: mat-light-theme(
    mat-palette($flxion-primary),
    mat-palette($flxion-accent),
    mat-palette($flxion-warn)
);

$primary-dark: darken( map-get($flxion-primary, 500), 8% );

$color-default: rgba(0, 0, 0, 0.87); // actually defined elsewhere too
$color-grey: #999;
$color-lightgrey: #ccc;

@keyframes pulse {
    0% { opacity: 1 }
    50% { opacity: .5 }
    100% { opacity: 1 }
}

.flxion {
    @include angular-material-theme($matx-theme);
    .mat-input-element {
        caret-color: currentColor;
    }

    --color-primary: #{$color-primary};
    --color-accent: #{$color-accent};
    --color-warn: #{$color-warn};
    --color-default: #{$color-default};

    --color-grey: #{$color-grey}; // #999
    --color-grey-1: #fafafa;
    --color-grey-2: #f1f1f1; // was f5f5f5 before .mat-drawer-container's background was changed from fff to fafafa (its default)
    --color-grey-3: #eee;
    --color-grey-4: #dcdcdc;
    --color-grey-5: #ccc;

    // Responsive spacing.
    --card-border-radius: 12px;
    --content-pad-hor: 10px;
    --box-pad-hor: 17px;
    --topnav-height-1: #{$topnav-height-small};
    --topnav-height-2: #{$topnav-height-small};
    @media screen and (min-width: 380px) {
        --content-pad-hor: 16px;
        --card-border-radius: 16px;
        --box-pad-hor: 20px;
    }
    @media screen and (min-width: 600px) {
        --content-pad-hor: 24px;
        --box-pad-hor: 24px;
        --topnav-height-1: #{$topnav-height-1};
        --topnav-height-2: #{$topnav-height-2};
        //&.scrolled {
        //    --topnav-height-2: 0px;
        //}
    }
    @media screen and (min-width: 1024px) {
        --content-pad-hor: 32px;
    }

    .mat-bg-primary,
    .topbar,
    .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
    .fileupload-drop-zone.dz-file-over,
    .toolbar-avatar.online > .status-dot,
    .cal-open-day-events {
        background: map-get($flxion-primary, 500) !important;
        color: #ffffff !important;
    }
    .mat-color-primary,
    .list-item-active {
        color: map-get($flxion-primary, 500) !important;
    }
    .topnav ul.menu > li > div.open > a,
    .topnav ul.menu > li > div.open > div,
    .list-item-active {
        border-color: map-get($flxion-primary, 500) !important;
        font-weight: 400;
    }
    .sidebar-compact ul.submenu,
    .default-bg {
        background: #ffffff !important;
    }

    .mat-bg-accent {
        background-color: map-get($flxion-accent, 500) !important;
        color: black;
    }
    .mat-bg-warn {
        background-color: map-get($flxion-warn, 500) !important;
        color: white;
    }
    .mat-color-accent {
        color: map-get($flxion-accent, 500) !important;
    }
    .mat-color-warn {
        color: map-get($flxion-warn, 500) !important;
    }
    .mat-color-default {
        color: $color-default !important;
    }
    .mat-color-grey {
        color: $color-grey !important;
    }
    .mat-color-lightgrey {
        color: $color-lightgrey !important;
    }

    @media (max-width: 959.9px) {
      .default-bg-mobile {
        background: #ffffff;
      }
    }


    // Added by Joris
    .mat-drawer-container {
        //background: #fff;
        min-height: 100vh;
    }
    .mat-sidenav {
        top: calc(var(--topnav-height-1) + var(--topnav-height-2)) !important;
    }

    .mat-dialog-container {
        border-radius: 16px;
        // Fix for the disappearing padding-bottom when there is a scrollbar:
        padding-bottom: 0;
        &:after {
            content: '';
            height: 24px;
            display: block;
        }
    }
    .mat-dialog-actions {
        justify-content: end;
    }
    .panel-lightgrey .mat-dialog-container {
        background: var(--color-grey-1);
    }
    .task-dialog {
        width: 800px;
        max-width: 92.5vw !important;
        max-height: 95vh;
        .mat-dialog-container {
            padding: 0;
            &:after {
                display: none;
            }
        }
    }

    // Card stuff
    --card-shadow:
        0 1px 4px 0 rgba(0, 0, 0, 0.08),
        0 2px 5px 0 rgba(0, 0, 0, 0.08),
        0 1px 8px 0 rgba(0, 0, 0, 0.06);
    //--card-selected-shadow: // (this is a green one)
    //    0 1px 4px 0 rgba(160, 188, 51, 0.24),
    //    0 2px 5px 0 rgba(160, 188, 51, 0.24),
    //    0 1px 8px 0 rgba(160, 188, 51, 0.18);
    --card-selected-shadow:
        0 1px 6px 0 rgba(0, 0, 0, 0.20),
        0 2px 7px 0 rgba(0, 0, 0, 0.20),
        0 2px 12px 0 rgba(0, 0, 0, 0.15);
    .mat-card {
        border-radius: var(--card-border-radius);
        padding: 0;
        &:not([class*="mat-elevation-z"]) {
            box-shadow: var(--card-shadow);
            &.selected-card {
                box-shadow: var(--card-selected-shadow);
            }
            &.cards-2 {
                box-shadow:
                    /* first shadow */
                    var(--card-shadow),
                    /* margin */
                    0 11px white,
                    /* second shadow */
                    0 12px 4px 0 rgba(0, 0, 0, 0.08),
                    0 13px 5px 0 rgba(0, 0, 0, 0.08),
                    0 12px 8px 0 rgba(0, 0, 0, 0.06);
            }
            &.cards-3 {
                box-shadow:
                    /* first shadow */
                    var(--card-shadow),
                    /* margin */
                    0 11px white,
                    /* second shadow */
                    0 12px 4px 0 rgba(0, 0, 0, 0.08),
                    0 13px 5px 0 rgba(0, 0, 0, 0.08),
                    0 12px 8px 0 rgba(0, 0, 0, 0.06),
                    /* margin */
                    0 22px white,
                    /* third shadow */
                    0 23px 4px 0 rgba(0, 0, 0, 0.08),
                    0 24px 5px 0 rgba(0, 0, 0, 0.08),
                    0 23px 8px 0 rgba(0, 0, 0, 0.06);
            }
        }
        &.collapsed {
            overflow: hidden;
            .mat-collapse-button {
                transform: rotate(-180deg);
            }
            .mat-card-content {
                display: none !important;
            }
        }
        &.binder-circle {
            box-shadow: var(--card-shadow), -14px 0px 5px -2px rgba(255, 255, 255, 0.65), -15px 0 0 rgba(0, 0, 0, 0.22);
            > .mat-card-content {
                min-height: 280px;
            }
            &.selected-card {
                box-shadow: var(--card-selected-shadow), -14px 0px 5px -2px rgba(255, 255, 255, 0.65), -15px 0 0 rgba(0, 0, 0, 0.22);
            }
            &:before, &:after {
                content: "· ·";
                position: absolute;
                left: -10px;
                height: 0;
                border-radius: 4px;
                width: 15px;
                box-shadow: 5px 14px 0 1px rgba(255,255,255,.4), 5px 18px 0 1px rgba(255,255,255,.4), 5px 16px 0 2px #777;
                line-height: 30px;
                font-size: 34px;
                font-weight: 500;
                white-space: nowrap;
                opacity: .8;
            }
            &:before {
                top: 28px;
            }
            &:after {
                bottom: 56px;
            }
        }
        &.binder-sphere {
            > .mat-card-content {
                min-height: 260px;
            }
            > div {
                position: absolute;
                top: 0;
                left: -8px;
                width: 24px;
                height: 100%;
                padding-top: 26px;
                overflow: hidden;
                font-size: 37px;
                font-weight: 500;
                opacity: .8;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                > p {
                    width: 14px;
                    margin-left: 10px;
                    text-align: right;
                    line-height: 0;
                    margin-bottom: 21px;
                    transform: rotate(30deg);
                    box-shadow: -6px 2px 0 1px #555, -6px 2px 0px 2px #bbb;
                }
            }
            .card-title-text, > .mat-card-content {
                padding-left: 30px;
            }
        }

        transition: transform 1s;
        &.no-transition {
            transition: inherit;
        }
        &.grow {
            transform: scale(1.125);
        }
    }
    .br-4 {
        border-radius: 4px;
    }
    .mat-subheader {
        cursor: default;
    }
    .card-title-text {
        padding: 8px var(--box-pad-hor);
        border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
        background: var(--color-grey-2);
        font-size: 1.125rem; // 18px
        &.has-button {
            padding-top: 1px;
            padding-right: calc(var(--box-pad-hor) - 12px);
            padding-bottom: 2px;
            min-height: 43px;
            display: flex;
            align-items: center;
            > div {
                flex: 1;
            }
            mat-icon + div {
                padding-left: 5px;
            }
            > span {
                font-size: 14px;
                padding-right: 12px;
            }
        }
        h3 {
            flex: 1;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        border-bottom: 1px solid var(--color-grey-4);
    }
    .not-mine .card-title-text, .card-title-text[class*='bg-color-'] {
        background: none;
        border-bottom: 1px solid var(--color-grey-3);
    }
    .mat-card-title {
        margin-bottom: 0;
        font-weight: 400;
        &:last-child > .card-title-text {
            border-radius: var(--card-border-radius);
        }
    }
    .mat-collapse-button {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .2s;
    }
    .card-title-text > .mat-icon, .card-title-text > div:first-child > .mat-icon {
        vertical-align: text-bottom;
    }
    .mat-card-content {
        padding: 0.5rem var(--box-pad-hor);
        .mat-table:last-child {
            border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
            .mat-row:last-child, .mat-footer-row {
                border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
            }
        }
        .mat-list:last-child {
            border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
            > :last-child {
                border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
            }
        }
    }

    .mat-card-content.has-tabs {
        padding-right: 54px;
        position: relative;
        > nav {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            transform: rotate(180deg);
            border-top-left-radius: var(--card-border-radius);
            overflow: hidden;
            font-weight: 400;

            > a, > span {
                flex: 1;
                writing-mode: vertical-rl;
                background: lightgray;
                line-height: 32px;
                width: 32px;
                text-align: center;
                box-shadow: inset -2px -1px 1px 0 rgba(0, 0, 0, 0.3);
                opacity: .9;
                color: rgba(0, 0, 0, 0.75);
                transition: box-shadow .2s, background .2s;
                &.active {
                    box-shadow: inset -1px 0 2px 0 var(--color-grey-4);
                    background: var(--color-grey-2);
                }
            }
            > a {
                max-height: 133px;
                flex-grow: 99;
            }
            > span {
                opacity: .5;
            }
        }
    }


    .box {
        padding: 1rem;
        position: relative;
        border: 1px solid var(--color-grey-4);
        border-radius: .5rem;
        --box-pad-hor: 0;
        > label {
            position: absolute;
            left: 12px;
            top: -6px;
            padding: 0 0.4em;
            line-height: 1.125;
            font-family: $font-family-inputs;
            font-size: 13px;
            color: rgb(0, 0, 0, 0.6);
            background: white;
        }

        > quill-editor {
            margin: -8px -1rem -1rem;
        }
        .ql-snow .ql-editor {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .ql-toolbar.ql-snow {
            padding-left: 1rem;
        }

        .mat-list-base[dense] {
            //margin-left: -12px;
            //margin-right: -12px;
            .mat-list-item .mat-list-item-content, .mat-list-option .mat-list-item-content {
                padding: 0;
            }
        }
    }

    // Overwrite default font for form fields.
    // And for lists, to make them readable enough.
    .mat-form-field, .ql-container, .mat-row {
        font-family: $font-family-inputs;
        font-weight: 400;
        h1, h2, h3, h4, h5, h6 {
            font-weight: 500;
        }
    }
    // List items also appear in menu's so we'll keep that in the original font.
    .mat-list-item {
        font-weight: 400;
    }
    .ql-container {
        font-size: 14px; // overwrite default of 13
        strong, b {
            font-weight: 600;
        }
    }
    .mat-form-field, .mat-form-field-infix {
        width: 100%;
    }
    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: inherit;
    }

    // Make disabled fields look like regular text (for viewers)
    .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
        display: none;
    }
    .mat-input-element[disabled]::placeholder {
        color: transparent;
    }
    .mat-form-field.mat-form-field-disabled:not(.mat-form-field-should-float) label {
        opacity: .25;
    }
    .mat-input-element:disabled,
    .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: inherit;
    }

    .mat-form-field-appearance-standard.tight {
        margin-bottom: -.2em;
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-form-field-underline {
            bottom: 0;
        }
        .mat-form-field-infix {
            padding: 0.15em 0 0.45em;
            margin-bottom: -.15em;
            border-top-width: 0;
        }
    }

    .swimlane .mat-form-field-appearance-standard {
        .mat-form-field-underline {
            visibility: hidden;
        }
        &:hover .mat-form-field-underline, &.mat-focused .mat-form-field-underline {
            visibility: visible;
        }
    }

    mat-header-row {
        min-height: 40px;
    }
    .mat-header-cell {
        font-weight: 400;
    }
    .mat-row, mat-list-item.cdk-drag {
        transition: background-color .4s;
        &:hover {
            background-color: map-get($flxion-accent, 50);
        }
    }
    .mat-row, .mat-footer-row {
        border-bottom-width: 0;
    }
    .mat-row + .mat-footer-row {
        border-top: 1px solid rgba(0, 0, 0, .12);
    }
    .cdk-drag {
        cursor: pointer;
    }
    .mat-form-field.readonly {
        margin-bottom: -1.25em;
    }
    .mat-tooltip {
        font-size: 12px;
        white-space: pre-line;
    }
    mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
        padding-left: var(--box-pad-hor);
    }
    mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
        padding-right: var(--box-pad-hor);
    }

    // Password visibility icon inside the field
    .pw-icon {
        color: #ccc;
    }
    .mat-focused .pw-icon {
        color: map-get($flxion-primary, 500);
    }
    .mat-form-field-invalid .pw-icon {
        color: map-get($flxion-warn, 500);
    }

    // For pushing icon buttons that are at the right side of a row, into the padding.
    .push-right {
        margin-right: -8px;
        margin-left: 8px; // avoid messing up the row header's alignment
    }
    // For pushing icon buttons that are at the left side of a row, into the padding.
    .push-left {
        margin-left: -8px;
        margin-right: 8px; // avoid messing up the row header's alignment
    }

    // Button styles
    .mat-raised-button, .mat-stroked-button, .mat-flat-button {
        border-radius: 12px;
    }
    .mat-raised-button {
        box-shadow: none !important;
    }
    .mat-checkbox-frame {
        border-width: 1px;
        border-radius: 4px;
    }
    mat-checkbox + mat-error {
        font-size: .75em;
        font-family: $font-family-inputs;
        font-weight: 400;
        margin-left: 24px;
    }

    // Try increase the outlined form fields' border radius as much as possible.
    .mat-form-field-appearance-outline .mat-form-field-outline-start {
        border-radius: 7px 0 0 7px;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border-radius: 0 7px 7px 0;
    }
    .mat-form-field-appearance-standard .mat-form-field-flex {
        padding-top: .4em;
    }

    .mat-list-base[dense] .mat-list-item .mat-list-text > *, .mat-list-base[dense] .mat-list-option .mat-list-text > * {
        font-weight: $font-weight-base;
    }

    .mat-nav-list .mat-icon {
        margin-right: 12px;
    }

    // Comments
    .chats-wrap .conversations-hold .single-conversation.assignee .conversation-msg {
        background: map-get($flxion-primary, 700) !important;
        color: #ffffff !important;
    }
    .chats-wrap .conversations-hold .single-conversation.sender .conversation-msg {
        border: 1px solid map-get($flxion-primary, 200) !important;
    }
    .conversation-msg a {
        font-weight: 400;
        color: map-get($flxion-primary, 500);
    }

    // Categories
    // (for colors, see colors.ts)
    .cat-color {
        width: 1em;
        height: 1em;
        display: inline-block;
        border-radius: 50%;
        margin-right: 7px;
        &.bg-color-0 {
            background: #ddd;
        }
    }
    div > .cat-color {
        vertical-align: text-top;
    }

    .hidden {
        display: none !important;
    }

    hr {
        border: 0;
        margin: -1px 0 1px;
        box-shadow: 0 1px currentColor;
        height: 1px;
        color: #ddd; /* Must be an opaque color, because shadows overlap. */
        &.wide {
            // 'cut' into any left and right paddings
            box-shadow: 100px 1px currentColor, -100px 1px currentColor;
        }
    }

    abbr {
        border-bottom: 1px dashed #999;
        text-decoration: none;
        white-space: nowrap;
    }

    .mat-chip {
        font-weight: 400;
        .mat-icon {
            vertical-align: middle;
        }
    }

    .mat-icon {
        // This makes the width/height follow the font-size, instead of hardcoding that.
        height: 1em;
        width: 1em;
    }
    .mat-icon.info {
        font-size: .875em;
        height: 1.143em;
        margin-left: .4em;
        cursor: help;
        width: auto;
        vertical-align: bottom;
    }

    .activatable {
        color: var(--color-grey-4);
        transition: color .4s;
        &:hover {
            color: var(--color-grey);
        }
    }

    @for $num from 1 through 16 {
        .color-#{$num} {
            color: #{map-get($flxion-colors, $num)} !important;
        }
        .bg-color-#{$num} {
            background-color: #{map-get($flxion-colors, $num)} !important;
            color: #{map-get(map-get($flxion-colors, contrast), $num)} !important;
        }
        --color-#{$num}: #{map-get($flxion-colors, $num)};
    }

    --menu-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.07);
}

.has-spacer {
    display: flex;
    > * {
        flex-grow: 0;
        &.spacer {
            flex-grow: 1;
        }
    }
}
.mat-dialog-title.has-spacer {
    // This is needed to avoid having to use !important.
    display: flex;
}
.spacer {
    flex-grow: 1;
}

span.prefix, span.code_ {
    font-weight: 500;
    white-space: nowrap;
    flex-shrink: 0;
}
span.prefix:after {
    content: '›';
    padding: 0 .34em;
}
span.code_:after {
    content: ':';
    padding-right: .34em;
}

.flex-has-nowrap {
    width: 0;
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    > div {
        // Fixing an ellipse bug on Safari
        width: 100%;
    }
}

.mat-autocomplete-panel {
    // Display the keyboard_return icon (if present) on the right side of the option.
    .mat-option-text {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        > .mat-icon:last-child {
            margin-right: 0;
        }
        > span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    // Display the keyboard_return icon only on the active option.
    .mat-option:not(.mat-active) .mat-icon {
        display: none;
    }
}

.not-allowed {
    cursor: not-allowed !important;
}

.textlink {
    color: var(--color-primary);
    font-weight: 500;
}

// Used under Tasks table and Documents table.
.add-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    min-height: 32px;
    box-sizing: content-box;
}

// Icons for the status of users.
.status-icon {
    font-size: 17px;
    height: auto;
    width: auto;
    vertical-align: middle;
    margin-left: 1ex;
    cursor: default;
}

.user-line > .mat-list-item-content {
    display: flex;
    align-items: center;
    .user-name {
        flex: 9;
    }
    .user-org {
        font-weight: 300;
        margin-left: 6px;
        opacity: .8;
    }
    .icon-on-hover {
        visibility: hidden;
    }
    &:hover .icon-on-hover {
        visibility: visible;
    }
    .mat-icon-button:last-child {
        margin-right: -5px;
        .mat-icon {
            font-size: 21px;
        }
    }
}
// Holder for the app-user-avatar.
.user-avatar {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 18px;
    cursor: default;
    &.can-change {
        cursor: pointer;
    }
    > app-user-avatar {
        width: 32px;
    }
    > .response {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: inherit;
        font-family: Roboto, sans-serif;
        right: -6px;
        bottom: -2px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid white;
        &.accepted {
            background: map-get($flxion-primary, 200);
            color: map-get($flxion-primary, 900);
        }
        &.declined {
            background: map-get($flxion-warn, 200);
            color: map-get($flxion-warn, 900);
        }
        &.tentative {
            background: map-get($flxion-accent, 200);
            color: map-get($flxion-accent, 900);
        }
        &.pending {
            background: #d2d2d2;
            color: #6a6a6a;
        }
        > .mat-icon {
            font-size: 12px;
        }
    }
}

// Contact icons
.user-contact-icons {
    flex: 0 0 96px;
    box-sizing: content-box;
    text-align: right;
    .mat-icon {
        vertical-align: middle;
        font-size: 21px;
    }
    .mat-icon, .linkedin {
        margin-left: 10px;
    }
    .linkedin {
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 13px;
        background: var(--color-grey-5);
        color: white;
        padding: 3px;
        line-height: .9;
        display: inline-block;
        border-radius: 4px;
    }
}

.mat-list-base[dense] {
    padding-top: 8px;
    .mat-list-item.list-user-add {
        padding-left: 50px;
        box-sizing: border-box;
        height: 52px;
    }
}

#tooltip {
    position: absolute;
    z-index: 999999;
    background: #fff;
    // It already has mat-elevation class for outside box-shadow
    padding: 16px var(--box-pad-hor);
    font-size: 13px;
    border-radius: 16px;
    max-height: 50vh;
    max-width: 80vw;
    overflow: hidden;

    &.hidden {
        visibility: hidden;
        pointer-events: none;
    }

    /** Quill styles **/
    p {
        margin-bottom: .25em;
    }
}

.breadcrumb {
    font-size: 30px;
    margin: -10px 0 34px;
    font-weight: 300;
    >.prefix, >.code_ {
        font-weight: 400;
    }
}

.swimlane h2, .swimlane-menu h2 {
    font-size: 22px;
    font-weight: 500;
    white-space: nowrap;
}
.swimlane {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h2 {
        margin-bottom: 0;
        + button {
            margin-left: var(--box-pad-hor);
        }
    }
    .mat-icon {
        font-size: 28px;
    }
    [type=submit] .mat-icon {
        font-size: 48px;
    }
}
.swimlane-menu {
    display: flex;
    align-items: center;
    h2 {
        white-space: nowrap;
        margin: 0;
        > i {
            // These are those | dividing the titles
            font-style: inherit;
            font-weight: 300;
            padding: 0 16px;
        }
        & + h2 {
            color: var(--color-grey-5);
            font-weight: 300;
            &:before {
                content: '|';
                padding: 0 22px;
            }
            a {
                transition: color .2s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}


/**
 * Dragging inside Material Tables
 */
.cdk-drag-preview.mat-row {
    background: rgba(255,255,255,.75);
}
.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


/**
 * Scrollbar
 */
html, mat-dialog-container, .thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #ccc rgba(0,0,0,.065);
}
html {
    overflow-y: scroll;
}
body, mat-dialog-container, .thin-scrollbar {
    &::-webkit-scrollbar {
        width: 7.5px;
        background: rgba(0,0,0,.065);
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-right: 1px solid rgba(0,0,0,.055);
    }
}

/**
 * Fade out the application upon logout
 */
body {
    transition-timing-function: ease-out;
    transition: 3s opacity;
    &.fading {
        background: black;
        opacity: 0.2;
    }
}

.fade-out, .fade-out-children > * {
    opacity: .4;
}

/**
 * Fix Quill list number in Firefox.
 */
.ql-editor ol li {
    counter-reset: ql-1 ql-2 ql-3 ql-4 ql-5 ql-6 ql-7 ql-8 ql-9;
    counter-increment: ql-0;
}
.ql-editor ol li:before {
    content: counter(ql-0, decimal) '. ';
}
.ql-editor ol li.ql-indent-1 {
    counter-increment: ql-1;
}
.ql-editor ol li.ql-indent-1:before {
    content: counter(ql-1, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-1 {
    counter-reset: ql-2 ql-3 ql-4 ql-5 ql-6 ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-2 {
    counter-increment: ql-2;
}
.ql-editor ol li.ql-indent-2:before {
    content: counter(ql-2, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-2 {
    counter-reset: ql-3 ql-4 ql-5 ql-6 ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-3 {
    counter-increment: ql-3;
}
.ql-editor ol li.ql-indent-3:before {
    content: counter(ql-3, decimal) '. ';
}
.ql-editor ol li.ql-indent-3 {
    counter-reset: ql-4 ql-5 ql-6 ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-4 {
    counter-increment: ql-4;
}
.ql-editor ol li.ql-indent-4:before {
    content: counter(ql-4, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-4 {
    counter-reset: ql-5 ql-6 ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-5 {
    counter-increment: ql-5;
}
.ql-editor ol li.ql-indent-5:before {
    content: counter(ql-5, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-5 {
    counter-reset: ql-6 ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-6 {
    counter-increment: ql-6;
}
.ql-editor ol li.ql-indent-6:before {
    content: counter(ql-6, decimal) '. ';
}
.ql-editor ol li.ql-indent-6 {
    counter-reset: ql-7 ql-8 ql-9;
}
.ql-editor ol li.ql-indent-7 {
    counter-increment: ql-7;
}
.ql-editor ol li.ql-indent-7:before {
    content: counter(ql-7, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-7 {
    counter-reset: ql-8 ql-9;
}
.ql-editor ol li.ql-indent-8 {
    counter-increment: ql-8;
}
.ql-editor ol li.ql-indent-8:before {
    content: counter(ql-8, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-8 {
    counter-reset: ql-9;
}
.ql-editor ol li.ql-indent-9 {
    counter-increment: ql-9;
}
.ql-editor ol li.ql-indent-9:before {
    content: counter(ql-9, decimal) '. ';
}

/**
 * Safari-specific css fixes (targets Safari 11+).
 */
@media not all and (min-resolution:.001dpcm) { @supports (-webkit-appearance:none) and (stroke-color:transparent) {

    html {
        scrollbar-width: auto;
    }

    @media screen and (min-width: 1280px) {
        .meeting-details .meeting-tasks {
            max-width: 47vw;
        }
    }

    flxion-tasks-table {
        .mat-column-title {
            max-width: 48vw;
            box-sizing: border-box;
        }
        .mat-column-meeting {
            max-width: 32vw;
            box-sizing: border-box;
        }
    }
    @media screen and (min-width: 1080px) {
        app-tasks-listing {
            .next, .soon {
                .mat-column-title {
                    max-width: calc(28vw - 60px);
                }
                .mat-column-meeting {
                    max-width: calc(19vw - 40px);
                }
            }
        }
    }
    @media screen and (min-width: 1280px) {
        .in_meeting {
            .mat-column-title {
                max-width: calc(28vw);
            }
        }
    }

}}
