quill-editor {
  display: flex;
  flex-direction: column;
}
.ql-toolbar.ql-snow {
  order: 1;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 15px;
  padding-right: 0;
  opacity: 0.4;
  transition: opacity .4s;
  &:hover {
    opacity: 1;
  }
}
.ql-container.ql-snow {
  border: 0;
}
[disabled] .ql-toolbar {
  display: none;
}
.ql-snow .ql-editor {
  padding: 1rem var(--box-pad-hor);
  &.ql-blank::before {
    left: var(--box-pad-hor);
  }

  p, ol, ul, pre, blockquote {
    margin-bottom: .4em;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5em;
    font-weight: $headings-font-weight;
    font-family: $font-family-base;
  }
  h1:not(:first-child), h2:not(:first-child), h3:not(:first-child), h4, h5, h6 {
    margin-top: 0.7em;
  }
  h1 {
    font-size: $font-size-h1;
  }
  h2 {
    font-size: $font-size-h2;
  }
  h3 {
    font-size: $font-size-h3;
  }
  h4 {
    font-size: $font-size-h4;
  }
  h5 {
    font-size: $font-size-h5;
  }
  h6 {
    font-size: $font-size-h6;
  }
}
