/*
 *
 *   MatX - Angular Material Design Admin Template
 *
 *
*/

// // Third pirty style files

// // Bootstrap Utility Classes
@import "bootstrap-utilities/bootstrap-utilities";

// // Sidebar Scroll
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// // Rich Text Editor
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

// // User tour
//@import "~hopscotch/dist/css/hopscotch.min.css";

// Main Variables File
@import "scss/variables";
@import "scss/functions/functions";
@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _themes.scss and remove unnecessary theme files
@import "scss/themes/themes";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";

@import "scss/page-layouts";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";
