// BACKGROUND SIZES
.background-size-cover {
  background-size: cover;
}
.background-size-contain {
  background-size: contain;
}
.background-size-100 {
  background-size: 100%;
}
