// General
$transitionDuration: 300ms !default;
$gutter: 1rem !default;
$transitionTiming: cubic-bezier(0.35, 0, 0.25, 1);
$sidebarTrnTime: 0.3s;

// Widths
$sidebar-width: 16.25rem !default;
$compact-sidebar-width: 64px !default;
$compact-big-sidebar-width: 150px !default;

$sidebar-navigation-icon-size: 18px;

// Typography
$font-family-base: Fira Sans, Roboto, "Helvetica Neue", sans-serif;
$font-family-inputs: Roboto, "Helvetica Neue", sans-serif;
$font-size-base: 0.875rem;
$font-weight-base: 300;
$font-weight-medium: 400;
$font-weight-bold: 500;

$font-size-h1: 18px !default;
$font-size-h2: 16.5px !default;
$font-size-h3: 15px !default;
$font-size-h4: 14px !default;
$font-size-h5: 14px !default;
$font-size-h6: 14px !default;

$headings-margin-bottom: 0.5rem !default;
$headings-font-family: inherit !default;
$headings-font-weight: 400;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

// Top navigation
$topnav-height-1: 55px;
$topnav-height-2: $topnav-height-1;
$topnav-height-small: 48px;
