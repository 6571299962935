$custom-typography: mat-typography-config(
    $font-family: $font-family-base
);
@include angular-material-typography($custom-typography);

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  margin-top: 0;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong,
b {
  font-weight: $font-weight-bold;
}

.strikethrough {
  text-decoration: line-through;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px;
    &.mat-icon {
      width: #{$i}px !important;
      height: #{$i}px !important;
      line-height: #{$i}px !important;
    }
  }
}

.font-weight-normal  { font-weight: $font-weight-base !important; }
.font-weight-medium  { font-weight: $font-weight-medium !important; }
.font-weight-bold    { font-weight: $font-weight-bold !important; }

.font-weight-inherit {
  font-weight: inherit;
}
.line-height-1 {
  line-height: 1 !important;
}

.whitespace-pre-line {
  white-space: pre-wrap;
}
.word-wrap-break-word {
  word-wrap: break-word;
}
